import { createDCHook, createMutationHook, collapseDataFromCall, collapseParams } from '../client';
import {
  getSwitchType,
  unsubscribeFromContactPreference,
  getCustomerRatings,
  chamberOfCommerceSearch,
  postCustomerOrderConfirmation,
  postCustomerDiscontinueIntake,
  postCustomerDiscontinueConfirm,
} from '../services/CustomerPublicService';

export const useCustomerPublicGetSwitchType = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(getSwitchType, 'requestBody', 'data'),
      'data',
      'productTypes',
      'customerIsMoving',
      'iban',
      'street',
      'postalCode',
      'houseNumber',
      'houseNumberSuffix',
    ),
  ),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      {
        target: 'data',
        props: ['productTypes', 'customerIsMoving', 'iban', 'street', 'postalCode', 'houseNumber', 'houseNumberSuffix'],
      },
    ],
  },
);

export const useCustomerPublicUnsubscribeFromContactPreference = createMutationHook(unsubscribeFromContactPreference, {
  injectables: ['label', 'businessUnit'],
  flattenData: false,
});

export const useCustomerPublicGetCustomerRatings = createDCHook(
  'getCustomerRatings',
  collapseDataFromCall(getCustomerRatings),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useCustomerPublicChamberOfCommerceSearch = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(chamberOfCommerceSearch, 'requestBody', 'data'),
      'data',
      'chamberOfCommerceNumber',
      'rsin',
      'locationNumber',
      'name',
      'street',
      'city',
      'postalCode',
      'houseNumber',
      'houseNumberSuffix',
      'type',
      'includeInactiveRegistries',
      'page',
      'amount',
    ),
  ),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      {
        target: 'data',
        props: [
          'chamberOfCommerceNumber',
          'rsin',
          'locationNumber',
          'name',
          'street',
          'city',
          'postalCode',
          'houseNumber',
          'houseNumberSuffix',
          'type',
          'includeInactiveRegistries',
          'page',
          'amount',
        ],
      },
    ],
  },
);

export const useCustomerPublicPostCustomerOrderConfirmation = createMutationHook(postCustomerOrderConfirmation, {
  injectables: ['label', 'businessUnit'],
  flattenData: false,
});

export const useCustomerPublicPostCustomerDiscontinueIntake = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(postCustomerDiscontinueIntake, 'requestBody', 'data'),
      'data',
      'postalCode',
      'houseNumber',
      'houseNumberSuffix',
    ),
  ),
  {
    injectables: ['label', 'customerId', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['postalCode', 'houseNumber', 'houseNumberSuffix'] },
    ],
  },
);

export const useCustomerPublicPostCustomerDiscontinueConfirm = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(postCustomerDiscontinueConfirm, 'requestBody', 'data'),
      'data',
      'customer',
      'contactPerson',
    ),
  ),
  {
    injectables: ['label', 'customerId', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['customer', 'contactPerson'] },
    ],
  },
);
